<template lang="pug">
.container.py-5
  .row
    .col-md-12.py-5
      .help-title.fs-5.fw-bold.pb-4 {{ title }}
      .help-details
        .description
          p {{ description }}
          .help-image.py-4(v-if="imgType === true ")
            img.img-fluid(:src="'/images/help/' + descriptionImage")
        .detail-explanation
          .explanation-title.fs-5.fw-bold {{ explanationTitle }}
          .explanation-description
            p {{ explanationDesc }}
            img.img-fluid(:src="'/images/help/' + explanationImage")
</template>

<script>
export default {
  name: "HelpCenter",
  props: {
    title: String,
    description: String,
    descriptionImage: String,
    explanationTitle: String,
    explanationDesc: String,
    imgType: Boolean,
    explanationImage: String,
  },
};
</script>
