<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")

.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in searchFilterList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === 'yes'")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === 'yes'")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import HelpCenter from '../../../components/HelpCenter';
import BackHelp from "../../../components/BackHelp";
export default {
  name: "LeadGroup",
  components: { HelpCenter, Footer, Navbar, BackHelp },
  data() {
    return {
      search: "",
      LeadGroup: [
        {
          title: "2.3 How to set up a lead group?",
          description: [
            {
              text: "Create and use groups to manage and organize your customers based on their interest, stage, background and any other category you like. You can label each group with colors and notes. You can have unlimited contacts in the group and each client with multiple groups.",
              imgType: "no",
            },
            {
              text: "1. Homepage > Tap on the “Group”. You shall land on the group landing page.",
              imgType: "yes",
              img: "89.Home – Empty group.png",
            },
            {
              text: "2. Tap on “Create group” to create a new group, with the following fields: \u0041) Group Name is the title of the group. Each group must have a unique name. \u0042) Group Color lets you choose the color of the group label, to visually identify it. ",
              imgType: "yes",
              img: "91. Create group.png",
            },
            {
              text: "3. Once you’re done, tap the “Create group” button to save it to your Groups list.",
              imgType: "yes",
              img: "93. Create group – 2.png",
            },
            {
              text: "4. Select the contacts you wish to add in this new group. A group can have unlimited contacts, and each contact can be added to multiple groups at a time.",
              imgType: "yes",
              img: "74. Import phonebook_select all – 1.png",
            },
            {
              text: "5. You will see a message which indicates that you have successfully added a group. ",
              imgType: "yes",
              img: "94.Home – group created.png",
            },
          ],
        },
      ],
    };
  },
  computed: {
    searchFilterList() {
      return this.LeadGroup.filter((item) => {
        return item.title.toLowerCase().includes(this.search);
      });
    },
  },
};
</script>
